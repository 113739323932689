.SingleDatePicker_picker {
  z-index: 999999
}

.CalendarDay__selected {
  background: #feac00;
  border: 1px double #feac00;
}

.CalendarDay__selected:hover {
  background: #feac00;
  border: 1px double #feac00;
}
